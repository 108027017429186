//
// Variables
// --------------------------------------------------
@contactIconColor:                  @themeColor2;
@contactFooterBorderColor:          @themeColor1;
@contactButtonTextColor:            @whiteColor;
@contactButtonBgColor:              @themeColor1;
@contactButtonBorderColor:          @themeColor2;
@contactButtonTextColorHover:       @whiteColor;
@contactButtonBgColorHover:         @themeColor2;
@contactButtonBorderColorHover:     @themeColor1;

//
// Essential classes
//---------------------------------------------------
.tile-padding {
    padding-right: 10px;
    padding-left: 10px;
}

//
// Contacts
//---------------------------------------------------
.contact-item {
    &__text {
        font-size: 0;
        > ul {
            padding: 0;
            vertical-align: top;
            > li {
                font-size: 14px;
                display: block;
                padding: 0 30px 0px 40px;
                margin-bottom: 15px;
                position:relative;
                min-height:25px;
                &:before, &::before {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    font-size: 22px;
                    content: "";
                    position:absolute;
                    left:0;        
                    color: @contactIconColor;               
                }
                &.map-marker {&:before, &::before{content: "\f041";}}
                &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
                &.envelope {&:before, &::before{content: "\f003";margin-top: -3px;}}
            }
        }
    }
    &__footer {
        padding: 30px 10px 0 0;
        border-top: 1px solid @contactFooterBorderColor;       
        margin-bottom: 20px;  
    }
    &__btn {
        color: @contactButtonTextColor;
        background-color: @contactButtonBgColor;
        border-color: @contactButtonBorderColor;
        
        &:hover, &:focus {
            color: @contactButtonTextColorHover;
            background-color: @contactButtonBgColorHover;
            border-color: @contactButtonBorderColorHover;
            text-decoration: none;
        }

        // Medium devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
            font-size: 20px;
            padding: 9px 18px;
            margin: 0 auto;
        }
    }
}
