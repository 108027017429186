.section {
    padding-top: 20px;
	padding-bottom: 20px;
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;

	// min Small devices
	@media (min-width: @screen-md-min) {
	    padding-top: 50px;
		padding-bottom: 50px;
	}
    &--bg {
    	background-color: @blackColor;
    }
    &--slider {
    	margin-top: -20px;
    	padding-top: 0;
    	padding-bottom: 0px;
    }
}