//
// Variables
// --------------------------------------------------
@articleButtonBgColor:             @themeColor1;
@articleButtonBgColorHover:        @themeColor2;
@articleButtonTextColor:           @whiteColor;
@articleButtonTextColorHover:      @whiteColor;
@articleButtonBorderColor:         @themeColor2;
@articleButtonBorderColorHover:    @themeColor1;

//
// Essential classes
//---------------------------------------------------
.article-padding {

}

//
// Articles
//---------------------------------------------------
.article-item {
    padding-bottom: 10px;
    border-bottom: 1px solid #3e3e3e;
    margin-bottom: 30px;
    &.detail {
        border-bottom: 0;
    }
    &__title {
        margin-top: 0;
        margin-bottom: 10px;
    }
    &__text {
        text-align: left;
        p {
            margin-bottom: 5px;
        }
    }
    &__image {
        font-size: 0;
        img { 
            margin: 15px auto;
            -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            @media (min-width: @screen-sm-min) {
                display: inline-block;
                margin: 10px auto 20px auto;
            }                
        }

    }
    &__btn.btn {
        color: @articleButtonTextColor;
        background-color: @articleButtonBgColor;
        border-color: @articleButtonBorderColor;

        // min Medium devices
        @media (min-width: @screen-md-min) {
            margin-top: 12px;
        }
        
        &:hover, &:focus {
            color: @whiteColor;
            background-color: @articleButtonBgColorHover;
            border-color: @articleButtonBorderColorHover;
            text-decoration: none;
        }
        &.back {
            min-width: 170px;
            margin-top: 20px;
            margin-bottom: 20px;
            // min Medium devices
            @media (min-width: @screen-md-min) {
                margin-top: 40px;
                margin-bottom: 40px;
            }
            &:after {
                display: none;
            }
        }
    }
}