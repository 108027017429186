.header {
    
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
from { -webkit-transform: rotateY(0deg);    }
25% { -webkit-transform: rotateY(0deg);    }
50% { -webkit-transform: rotateY(180deg);    }
75% { -webkit-transform: rotateY(360deg);    }
to   { -webkit-transform: rotateY(360deg); }
}

/* all other browsers */
@keyframes spinner {
from {
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
25% {
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
50% {
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
75% {
  -moz-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
to {
  -moz-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

}

.navbar {
	&-fixed-top {
	    -webkit-backface-visibility: hidden;
	}
	&-style {
		background-color: @themeColor1;
		border-radius: 0;
		border: 0;
		overflow: hidden;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
		@media (max-width: @grid-float-breakpoint-max) {
		}
	}
	> .container {
		.navbar {
			&-brand {
				height: inherit;
				padding: 0;
				@media (min-width: @grid-float-breakpoint) {
					margin-left: -45px;
				}
				img {
				    -webkit-animation-name: spinner;
				    -webkit-animation-timing-function: linear;
				    -webkit-animation-iteration-count: infinite;
				    -webkit-animation-duration: 6s;

				    animation-name: spinner;
				    animation-timing-function: linear;
				    animation-iteration-count: infinite;
				    animation-duration: 6s;

				    -webkit-transform-style: preserve-3d;
				    -moz-transform-style: preserve-3d;
				    -ms-transform-style: preserve-3d;
				    transform-style: preserve-3d;
					&:hover {
						-webkit-animation-play-state: paused;
						animation-play-state: paused;
					}
				}
			}
			&-nav {
					padding: 0 40px;
				@media (min-width: @grid-float-breakpoint) {
					padding: 0;
				}
				margin-top: 54px;
				> li {
					> a {
						font-size: 16px;
						padding-left: 5px;
						padding-right: 5px;
						color: @whiteColor;
						border-bottom: 5px solid transparent;
						@media (min-width: @grid-float-breakpoint) {
							padding-top: 12px;
							padding-bottom: 13px;
							padding-left: 25px;
							padding-right: 25px;
						}
					}
					&:hover a, &.active a, a:focus {
						background-color: transparent;
						color: @whiteColor;
						border-color: @themeColor2;
					}
				}
				&.navbar-right {
					@media (min-width: @grid-float-breakpoint) {
						margin-right: 0;
					}
				}
			}
		}
	}
	&-header {
		position: relative;
	}
	&-toggle {
		position: absolute;
		right: 20px;
		top: 50%;
		width: 60px;
		text-align: center;
		color: @themeColor2;
		border: 0;
		margin: -25px 0 0 0;
		padding: 0px 15px 0 15px;
		border-radius: 0;
		height: 50px;
		-webkit-transition: padding 0.3s ease-in-out, height 0.3s ease-in-out;
		-moz-transition:    padding 0.3s ease-in-out, height 0.3s ease-in-out;
		-ms-transition:     padding 0.3s ease-in-out, height 0.3s ease-in-out;
		-o-transition:      padding 0.3s ease-in-out, height 0.3s ease-in-out;
		transition:         padding 0.3s ease-in-out, height 0.3s ease-in-out;

		.icon-bar {
			background-color: @themeColor2;
			width: 100%;
			height: 3px;
			border-radius: 2px;
			-webkit-transition: transform 0.3s ease-in-out, transform-origin 0.3s ease-in-out, opacity 0.3s ease-in-out;
			-moz-transition:    transform 0.3s ease-in-out, transform-origin 0.3s ease-in-out, opacity 0.3s ease-in-out;
			-ms-transition:     transform 0.3s ease-in-out, transform-origin 0.3s ease-in-out, opacity 0.3s ease-in-out;
			-o-transition:      transform 0.3s ease-in-out, transform-origin 0.3s ease-in-out, opacity 0.3s ease-in-out;
			transition:         transform 0.3s ease-in-out, transform-origin 0.3s ease-in-out, opacity 0.3s ease-in-out;
			+ .icon-bar {
				margin-top: 5px;
			}
		}
		&[aria-expanded="true"] > .top-bar {
		  transform: translateX(4px) rotate(45deg);
		  transform-origin: 10% 10%;
		}
		&[aria-expanded="true"] > .middle-bar {
		  opacity: 0;
		}
		&[aria-expanded="true"] > .bottom-bar {
		  transform: translateX(4px) rotate(-45deg);
		  transform-origin: 10% 90%;
		}
		&.collapsed .middle-bar {
		  opacity: 1;
		}
		&:hover {
			background-color: transparent;
		}
		~ .visible {
			visibility: hidden;
			opacity: 0;
            -webkit-transition: visibility 0s ease-out 0.3s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -moz-transition:    visibility 0s ease-out 0.3s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -ms-transition:     visibility 0s ease-out 0.3s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -o-transition:      visibility 0s ease-out 0.3s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            transition:         visibility 0s ease-out 0.3s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;

		}
		&.collapsed ~ .visible {
			visibility: visible;
			opacity: 1;
            -webkit-transition: visibility 0s ease-in 0s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -moz-transition:    visibility 0s ease-in 0s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -ms-transition:     visibility 0s ease-in 0s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            -o-transition:      visibility 0s ease-in 0s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;
            transition:         visibility 0s ease-in 0s, height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, border-color 0.1s ease-in-out !important;

		}
	}
}