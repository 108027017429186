//
// Variables
// --------------------------------------------------
@gallery-overlayTextColor:       @whiteColor;
@gallery-overlayTextColorHover:  @themeColor2;

//
// Essential classes
//---------------------------------------------------

//
// Gallery
//---------------------------------------------------
.gallery-pic {
    text-align: center;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    overflow: hidden;

    &:hover, &--active {
        .gallery-pic__image:after, .gallery-pic__image::after {
            background-color: rgba(0, 0, 0, 0.6);
        }
        .gallery-pic__overlay {               
            top: 55%;
            opacity: 1;
        }
    }

    &__image {
        &:after, &::after {
            content: "";
            background-color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
    &__overlay {
        display: inline-block;
        position: absolute;
        top: 150%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        a {
            font-size: 32px;
            color: @gallery-overlayTextColor;
            padding: 6px;

            // Medium devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                font-size: 64px;
                padding: 12px;
            }

            &:hover {
                color: @gallery-overlayTextColorHover;
                text-decoration: none;
            }
        }
    }
}