//
// Body, html
//---------------------------------------------------
body {
    font-family: "open-sans",sans-serif;
    font-style: normal;
    font-weight: 300;
    background-color: #1e1e1e;
    color: @whiteColor;
    font-size: 14px;
}

//
// Typography
//---------------------------------------------------
h1, h2, h3, h4 {
    font-family: "hucklebuck-jf",sans-serif;
    font-style: normal;
    font-weight: 400;
    color: @themeColor2;
    margin-bottom: 15px;
    &.normal {
        font-family: "open-sans",sans-serif;
        font-style: normal;
        font-weight: 300;
        color: @whiteColor;
        font-size: 30px;
        line-height: 1.29;
    }
}

h1 {
    font-size: 35px;
}

h2 {
    font-size: 35px;
}

h3 {
    
}

p {
    margin: 0 0 20px;
}

ul {
    > li {

    }     
}

a {
    &:hover, &:focus, &:active {
        
    }
}

a,button {
    &.btn {
        margin-top: 18px;
        margin-bottom: 30px;
        color: @whiteColor;
        text-transform: uppercase;
        background: rgb(252,0,0);
        background: -moz-linear-gradient(top, rgba(252,0,0,1) 0%, rgba(162,11,11,1) 100%);
        background: -webkit-linear-gradient(top, rgba(252,0,0,1) 0%,rgba(162,11,11,1) 100%);
        background: linear-gradient(to bottom, rgba(252,0,0,1) 0%,rgba(162,11,11,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc0000', endColorstr='#a20b0b',GradientType=0 );
        border: 1px solid #d30404;
        border-width: 0 1px 0 0;
        padding: 11.5px 21px;
        font-size: 18px;
        // min Small devices
        @media (min-width: @screen-md-min) {
            margin-bottom: 10px;
        }
        &:after {
            content: " »";
            vertical-align: middle;
            font-size: 20px;
            line-height: 17px;
            height: 25px;
            display: inline-block;
            padding-left: 5px;
        }
        &:hover, &:focus, &.active {
            color: @whiteColor;
            background: rgb(252,0,0);
            filter: none;
        }
    }
}

span {
    &.big {
        font-size: 30px;
    }
}

img {
    &.img-thumbnail {
        background-color: #3c3c3c;
        border-color: #3c3c3c;
    }
    &.img-bordered {
        border: 1px solid #3e3e3e;
    }
}

iframe {
    border: 1px solid #3e3e3e;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    &.videogallery {
        margin-bottom: 30px;
    }
}

//
// Essential classes
//---------------------------------------------------

.modal-backdrop {
    top: 104px;
    &.in {
        opacity: 0.8;
    }
}

.modal {
  text-align: center;
  padding: 0!important;
}

.modal-header {
    border-bottom: 0;
}

.modal-content {
    background-color: #000;
    border: 1px solid #1c1c1c;
    .modal-body {
        padding: 0 20px;
    }
    p {
        margin-bottom: 5px;
    }
}

.modal-footer {
    border-top: 0;
    // max extraSmall devices
    @media (max-width: @screen-xs-max) {
        text-align: center;
    }
    .btn {
        width: 170px;
        float: left;

        // max extraSmall devices
        @media (max-width: @screen-xs-max) {
            float: none !important;
            width: 80%;
            margin-bottom: 5px;
        }
        &:after {
            display: none;
        }
        &-success {
            float: right;
            background: #82c716;
            background: -moz-linear-gradient(top, #82c716 0%, #507c0d 100%);
            background: -webkit-linear-gradient(top, #82c716 0%, #507c0d 100%);
            background: linear-gradient(to bottom, #82c716 0%, #507c0d 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#82c716', endColorstr='#507c0d', GradientType=0);
            border: 1px solid #507c0d;
            border-width: 0 1px 0 0;
            // max extraSmall devices
            @media (max-width: @screen-xs-max) {
                margin-top: 5px;
            }
            &:hover, &:focus, &.active {
                background-color: #82c716;
            }
        }
    }
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;

    // min Small devices
    @media (min-width: @screen-sm-min) {
        width: 510px;
    } 
}

.cookies {
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1020;
    overflow: hidden;
    min-height: 74px;
    background-color: #ed1c24;
    font-size: 16px;
    a {
        color: @whiteColor;
        text-decoration: underline;
        margin: 10px 0;
        display: inline-block;
        // min Small devices
        @media (min-width: @screen-sm-min) {
            margin: 26px 0;
        }
    }
    p {
        font-size: 14px;
        margin-bottom: 0;
        padding: 10px 0;

        // Small devices
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            padding: 15px 0;
            font-size: 16px;
        }
        // min Medium devices
        @media (min-width: @screen-md-min) {
            padding: 26px 0;
            font-size: 16px;
        }
    }
    .btn-black {
        display: block;
        width: 66.777777776%;
        background-color: #000;
        text-align: center;
        height: 30px;
        text-transform: uppercase;
        color: @whiteColor;
        border: 1px solid #525252;
        font-size: 14px;
        margin: 4px auto;

        // min Small devices
        @media (min-width: @screen-sm-min) {
            width: 100%;
            margin: 12px 0;
            font-size: 18px;
            height: 50px;
        }
    }
}

.no-scroll {
    margin: 0;
    width: 100%;
    height: 100%; 
    position: fixed;
    overflow-y:scroll;
}

.no-scroll-no-sb {
    margin: 0;
    width: 100%;
    height: 100%; 
    overflow-y:hidden;
}

.affix {
    top: 0;
    &, &-top, &-bottom {
    width: 100%;
    z-index: 999;
    }
}

//Extra small devices
@media (max-width: @screen-xs-max) { 
    .display-xs-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-xs-center {
        text-align: center;
    }
}

// Small devices
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
    .display-sm-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: @screen-md-min) { 
    .display-md-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-md-center {
        text-align: center;
    }
}

.m-0-a {
    margin: 0 auto;
}

.no-padding {
    padding: 0;
}

.shadow-inset {
    box-shadow:
        inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-b {
    box-shadow:
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t-b {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4),
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4); 
}

//
// Screen resolutions
//---------------------------------------------------
    // Extra small devices
    @media (max-width: @screen-xs-max) { 
    }

    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
    }

    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
    }

    // Large devices and Medium device
    @media (min-width: @screen-md-min) {
    }

    // Medium devices and Small devices and Extra small devices
    @media (max-width: @screen-md-max) {
    }

//
// Owl carousel fix
//---------------------------------------------------
.owl-carousel {
    z-index: 1;
    .animated {
        animation-duration: 2s;
        &.owl-animated-out {
            animation-duration: 2s;
        }
    }
}

.owl-wrapper-outer {
    z-index: 1;
}

.owl-stage {
    z-index: 1;
}

.owl-stage-outer {
    z-index: 2;
}

.owl-item {
    z-index: 1;
    .item {
        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 10px;
            -webkit-box-shadow: inset 0px -5px 10px -5px rgba(0,0,0,0.75);
            -moz-box-shadow: inset 0px -5px 10px -5px rgba(0,0,0,0.75);
            box-shadow: inset 0px -5px 10px -5px rgba(0,0,0,0.75);
        }
        // Extra small devices
        @media (max-width: @screen-xs-max) {
            background-image: none !important;
        }
    }
}

.owl-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 16px;
    right: 16px;
    margin-top: -29px;
    height: 0;
    overflow: visible;
    @media (min-width: @screen-sm-min) {
        margin-top: -58px;
    }
    .owl-prev, .owl-next {
        float: left;
        width: 14px;
        height: 58px;
        font-size: 0;
        background: url(../images/owl-nav.png) no-repeat; 
        background-size: auto 100%;
        @media (min-width: @screen-sm-min) {
            width: 28px;
            height: 116px;
        }
    }
    .owl-next {
        background-position: -14px 0;
        float: right;
        @media (min-width: @screen-sm-min) {
            background-position: -28px 0;
        }
    }
}

.page-header {
    border-bottom: 1px solid #3e3e3e;
}

section .page-header {
    margin-top: 10px;
}

.page-footer {
    padding-top: 15px;
    margin: 15px 0 20px;
    border-top: 1px solid #3e3e3e;
}

.virtual {
    display: inline-block;
    position: relative;
    &:hover {
        text-decoration: none;
    }
    &:before {
        content: url(../images/360.png);
        width: 62px;
        height: 62px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -31px;
        margin-left: -31px;
        overflow: hidden;
        transition-duration: 0.8s;
        transition-property: transform;
    }
    &:after {
        content: "360°";
        color: @whiteColor;
        font-size: 12px;
        font-weight: 800;
        line-height: 62px;
        text-align: center;
        width: 62px;
        height: 62px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
    }
    &:hover {
        &:before {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }
}

.form-control {
    height: 48px;
    border: 1px solid #9e9d9d;
    padding: 12px;
    &::-webkit-input-placeholder {
      color: #3e3e3e;
      font-weight: 700;
    }
    &:-ms-input-placeholder {
      color: #3e3e3e;
      font-weight: 700;
    }
    &::-moz-placeholder {
      color: #3e3e3e;
      font-weight: 700;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #3e3e3e;
      font-weight: 700;
      opacity: 1;
    }
}

.form-group {
    div[class*="col"] + div[class*="col"] > input {
        margin-top: 15px;
        @media (min-width: @screen-sm-min) {
            margin-top: 0;
        }
    }
}

.fb-page {
    margin-top: 20px;
}